import React from 'react'
import NotFound from '../../components/NotFound'

const notfound = () => {
  return (
    <>
      <NotFound />
    </>
  )
}

export default notfound