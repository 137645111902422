import React, { useEffect, useState } from 'react'
import Hero from '../../components/Hero'
// import { gsap } from 'gsap'

const Home = () => {
    // const home = gsap.timeline();
    // const [loaded, setLoaded] = useState(false)
    // useEffect(()=>{
    //   console.log('Home videoReady', loaded)
    // },[loaded])

  return (
    <>
      <Hero />
    </>
  )
}

export default Home