import React, { useEffect, useState} from 'react'
import { Link } from 'react-router-dom';

import "./notfound.css"

function NotFound() {

    const [imgSrc, setImgSrc] = useState('01.png');

    useEffect(()=> {
        const source = [
            '01.png',
            '02.png',
            '03.png',
            '04.png',
            '05.png',
            '06.png',
            '07.png',
            '08.png'
        ]

        let x = 0,
        y = 0,
        dirX = 1,
        dirY = 1,
        switchX = 0,
        switchY = 0;
        const speed = 2;

        let dvd = document.querySelector('#label')
        const canvas = document.querySelector('.bounce-container')

        let prevColorChoiceIndex = 0;

        let dvdWidth = dvd.clientWidth;
        let dvdHeight = dvd.clientHeight;

        console.log(dvdWidth);

        function getNewRandomColor() {
            const currentSource = [...source]
            currentSource.splice(prevColorChoiceIndex,1)
            const colorChoiceIndex = Math.floor(Math.random() * currentSource.length);
            prevColorChoiceIndex = colorChoiceIndex<prevColorChoiceIndex?colorChoiceIndex:colorChoiceIndex+1;
            const colorChoice = currentSource[colorChoiceIndex];
            
            return colorChoice;
        }

        function animate() {
            const screenHeight = canvas.clientHeight;
            const screenWidth = canvas.clientWidth;

            if ((y + dvdHeight >= screenHeight && switchY === 0 && dirY > 0) || y < 0) {
                dirY *= -1;
                setImgSrc(getNewRandomColor());
                switchY = 1;
            }
            if ((x + dvdWidth >= screenWidth && switchX === 0 && dirX > 0)|| x < 0) {
                dirX *= -1;
                setImgSrc(getNewRandomColor());
            }
            if(y + dvdHeight < screenHeight) {
                switchY = 0;
            }
            if(x + dvdWidth < screenWidth) {
                switchX = 0;
            }
            if(y + dvdHeight > screenHeight && dirY < 0) {
                y = screenHeight - dvdHeight;
            }
            if(x + dvdWidth > screenWidth && dirX < 0) {
                x = screenWidth - dvdWidth;
            }  

            x += dirX * speed;
            y += dirY * speed;
            dvd.style.left = x + "px";
            dvd.style.top = y + "px";

            window.requestAnimationFrame(animate);
        }

        window.requestAnimationFrame(animate);

    }, [])

    return (
        <div>
            <div className='bounce-container'>
                <img id='label' src={require(`../../public/asset/images/hero-2/${imgSrc}`)}/>
            </div>
            <div className='notfound-main-container'>
                <div className='notfound-container flex-1'>
                    <p className='four-zero pb-[15vw] sm:pb-[5vw] lg:pb-[3vw]'>404</p>
                    <p className='four-zero-link '>
                        <Link className='underline decoration-[#c8c5b8] decoration-2 underline-offset-4' to='/'>It's not you, it's me.</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default NotFound