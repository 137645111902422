import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./modules/home/index";
import NotFound from "./modules/notfound";
import ScrollToTop from './helpers/ScrollToTop'

function App() {
  return (
    <BrowserRouter>
      {/* <NavBar /> */}
      <ScrollToTop />
      <Routes>
        <Route index element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
